@font-face {
    font-family: 'Montserrat-Medium';
    src: url('./font/Montserrat-Medium.otf');
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Black';
    src: url('./font/Montserrat-Black.otf');
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url('./font/Montserrat-Bold.otf');
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-ExtraBold';
    src: url('./font/Montserrat-ExtraBold.otf');
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-ExtraLight';
    src: url('./font/Montserrat-ExtraLight.otf');
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Light';
    src: url('./font/Montserrat-Light.otf');
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Regular';
    src: url('./font/Montserrat-Regular.otf');
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url('./font/Montserrat-SemiBold.otf');
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Thin';
    src: url('./font/Montserrat-Thin.otf');
    font-style: normal;
}
