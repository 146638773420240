.advantage {
    background-color: #f8f8f8;
    padding-top: 93px;
    padding-bottom: 103px;
}

.advantageTitle {
    font-family: PingFangSC-Light;
    font-size: 36px;
    color: #333;
    letter-spacing: 0;
    text-align: center;
    font-weight: 200;
}

.advantageContent {
    margin-top: 74px;
    padding: 0 15.75vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.advantageCard {
    box-sizing: border-box;
    background: #fff;
    border-radius: 20px;
    padding: 36px 1.8vw;
    width: 20vw;
    height: 300px;
    position: relative;
    margin: 0 10px;
}

.advantageCardLogo {
    height: 60px;
    margin-bottom: 30px;
}

.advantageCardTitle {
    font-family: PingFangSC-Medium;
    font-size: 24px;
    letter-spacing: 0;
    font-weight: 500;
    margin-bottom: 4px;
}

.advantageIcon {
    margin-top: 40px;
    height: 20px;
}

.advantageCardDetail {
    font-family: PingFangSC-Light;
    font-size: 14px;
    color: #999;
    letter-spacing: 0;
    line-height: 26px;
    font-weight: 200;
    position: absolute;
    width: 12vw;
    top: 120px;
    opacity: 0;
}

.advantageCard:hover {
    background: #005def;
}

.advantageCard:hover .advantageCardLogo {
    display: none;
}

.advantageCard:hover .advantageCardDetail {
    opacity: 1;
}

/* .advantageCard:hover .advantageCardTitle {
    position: absolute;
    top: 50;
} */

.advantageCard:hover div {
    color: #fff;
}

.advantageCard:hover .advantageIcon {
    opacity: 0;
}
