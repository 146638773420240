.functionTitle {
    font-family: PingFangSC-Light;
    font-size: 36px;
    color: #333;
    letter-spacing: 0;
    text-align: center;
    font-weight: 200;
    margin-top: 92px;
}

.functionCard {
    /* padding: 0 12.75vw; */
    padding-left: 12.75vw;
    padding-right: 4.75vw;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    height: 600px;
}

.functionCardImg {
    width: 80%;
}

.functionCardLeft {
    width: 50%;
    margin-right: 10vw;
}

.functionCardRight {
    flex: 1 auto;
}

.functionCardLeftTitle {
    font-family: PingFangSC-Medium;
    font-size: 40px;
    color: #333;
    letter-spacing: 0;
    font-weight: 500;
}

.functionCardLeftDescription {
    font-family: PingFangSC-Light;
    font-size: 16px;
    color: #999;
    letter-spacing: 0;
    line-height: 30px;
    font-weight: 200;
    margin-top: 14px;
}
