.SolutionCarousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 7vw;
    padding-bottom: 100px;
    background-color: #f8f8f8;
}

.solutionCarouselTitle {
    margin-top: 100px;
    margin-bottom: 76px;
    font-family: PingFangSC-Light;
    font-size: 36px;
    color: #333;
    letter-spacing: 0;
    text-align: center;
    font-weight: 200;
}

.solutionCarouselContent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.solutionCarouselCard {
    width: 30vw;
    height: 260px;
    background-image: linear-gradient(90deg, #1e64d8 27%, rgba(30, 100, 216, 0.30) 100%);
    border-radius: 20px;
    padding: 42px 2vw;
    position: relative;
}

.solutionCarouselItemTitle {
    width: 24vw;
    font-family: PingFangSC-Medium;
    font-size: 24px;
    color: #fff;
    letter-spacing: 0;
    font-weight: 500;
    padding-bottom: 20px;
    border-bottom: 2px solid #fff;
}

.solutionCarouselItemDesc {
    width: 20vw;
    font-family: PingFangSC-Light;
    font-size: 14px;
    color: #fff;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 200;
    margin-top: 18px;
}

.solutionCarouselImg {
    width: 18vw;
    position: absolute;
    right: 0;
    bottom: -26px;
}

.solutionArrow {
    display: block;
    width: 2vw;
    cursor: not-allowed;
}

.solutionActiveArrow {
    display: block;
    width: 2vw;
    cursor: pointer;
}
