.solutionProducts {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 9.1vw;
}

.solutionProductsTitle {
    margin-top: 100px;
    margin-bottom: 76px;
    font-family: PingFangSC-Light;
    font-size: 36px;
    color: #333;
    letter-spacing: 0;
    text-align: center;
    font-weight: 200;
}

.solutionProductsContent {
    width: 100%;
    margin-bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.solutionProductsCard {
    box-sizing: border-box;
    background: #fff;
    border-radius: 20px;
    border: 1px solid #ccc;
    padding: 36px 1.8vw;
    width: 23vw;
    height: 400px;
    position: relative;
    margin: 0 10px;
}

.solutionProductsCardLogo {
    position: absolute;
    height: 8vw;
    right: 1vw;
    bottom: 20px;
}

.solutionProductsCardTitle {
    font-family: PingFangSC-Medium;
    font-size: 1.4vw;
    letter-spacing: 0;
    font-weight: 500;
    margin-bottom: 4px;
}

.solutionProductsLink {
    display: flex;
    align-items: center;
    margin-top: 13px;
    font-family: PingFangSC-Light;
    font-size: 14px;
    color: #999;
    letter-spacing: 0;
    text-align: justify;
    font-weight: 200;
    cursor: pointer;
}

.solutionProductsLinkDisabled {
    display: flex;
    align-items: center;
    margin-top: 13px;
    font-family: PingFangSC-Light;
    font-size: 14px;
    color: #999;
    letter-spacing: 0;
    text-align: justify;
    font-weight: 200;
    cursor: not-allowed;
}

.solutionProductsIcon {
    background: url('../../assets/images/home/linkto.svg');
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
    display: block;
    margin-left: 0.5vw;
}

.solutionProductsCardDetail {
    font-family: PingFangSC-Light;
    font-size: 14px;
    color: #fff;
    letter-spacing: 0;
    text-align: justify;
    line-height: 24px;
    font-weight: 200;
    position: absolute;
    width: 14vw;
    top: 6vw;
    opacity: 0;
}

.solutionProductsCard:hover {
    background: #005def;
}

.solutionProductsCard:hover .solutionProductsCardLogo {
    display: none;
}

.solutionProductsCard:hover .solutionProductsCardDetail {
    opacity: 1;
}

.solutionProductsCard:hover div {
    color: #fff;
}

.solutionProductsCard:hover .solutionProductsIcon {
    background: url('../../assets/images/home/linknone.svg');
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
    display: block;
    margin-left: 0.5vw;
}

.solutionProductsCard:hover .solutionProductsLink {
    position: absolute;
    bottom: 42px;
    font-family: PingFangSC-Light;
    font-size: 14px;
    color: #fff;
    letter-spacing: 0;
    text-align: justify;
    font-weight: 200;
}

.solutionProductsCard:hover .solutionProductsLinkDisabled {
    position: absolute;
    bottom: 42px;
    font-family: PingFangSC-Light;
    font-size: 14px;
    color: #fff;
    letter-spacing: 0;
    text-align: justify;
    font-weight: 200;
}

.solutionArrow {
    display: block;
    width: 2vw;
    cursor: not-allowed;
}

.solutionActiveArrow {
    display: block;
    width: 2vw;
    cursor: pointer;
}
