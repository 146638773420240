.trybody {
    /* width: 100vw; */
    background-size: cover;
    clear: both;
}

.tryBox {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 62px;
    padding-bottom: 73px;
    padding-left: 12.75vw;
    padding-right: 12.75vw;
}

.tryData {
    display: flex;
}

.tryDeveloper {
    margin-right: 72px;
    color: #fff;
}

.tryNumber {
    font-family: 'Montserrat-Light';
    font-size: 36px;
    color: #fff;
    letter-spacing: -0.4px;
    font-weight: 200;
}

.tryUnit {
    font-size: 26px;
}

.unitKm {
    font-size: 20px;
}

.unitW {
    font-family: PingFangSC-Thin;
    font-size: 24px;
    color: #fff;
    letter-spacing: -0.4px;
    line-height: 44px;
    font-weight: 200;
}

.tryWords {
    font-family: PingFangSC-Light;
    font-size: 16px;
    color: #fff;
    letter-spacing: -0.18px;
    font-weight: 200;
    margin-bottom: 16px;
}

.tryUnderLine {
    width: 180px;
    height: 1px;
    margin-top: 16px;
    background-color: #fff;
}

.try {
    display: flex;
    flex-direction: column;
    color: #333;
    align-items: center;
    /* margin-left: 200px; */
}

.tryTitle {
    font-family: PingFangSC-Light;
    font-size: 30px;
    color: #fff;
    letter-spacing: -0.33px;
    text-align: center;
    font-weight: 200;
}

.tryButton {
    padding: 11px 42px;
    background: #005def;
    border-radius: 20px;
    font-family: PingFangSC-Light;
    font-size: 18px;
    color: #fff;
    letter-spacing: -0.2px;
    font-weight: 200;
    margin-top: 22px;
}

.button:hover {
    background-color: #333;
    font-family: PingFangSC-Semibold;
}
