.banner-box {
    min-height: 500px;
}
.banner-module {
    position: relative;
    min-width: 1200px;
    /* max-width: 1920px; */
    width: 100%;
    overflow: hidden;
    min-height: 500px;
}

/* 此处的 width 跟随图进行变化 */
.componenet-wrap {
    /* width: 200%;
    width: 300%; */
}

/* 此处的 width 随着图片进行变化 */
.xcloud-banner {
    position: relative;
    /* width: 50%;
    width: 33%; */
    float: left;
    /* TODO 这里需要修改，但是56vh可能不是好选择 */
    /* height: 56vh; */
    height: 520px;
}

.xcloud-landpage-banner {
    /* height: 56vh; */
    height: 520px;
    position: relative;
    float: left;
}

.home-banner-wrap {
    position: absolute;
    top: 38%;
    left: 0;
    right: 0;
    width: 1200px;
    height: auto;
    margin: 0 auto;
    transition: all .2s ease-in-out;
}

.home-banner-wrap.xcloud-view-center {
    -webkit-animation: bannerkeysframe 1s ease-in-out;
    animation: bannerKeysFrame 1s ease-in-out;
}

.banner-module.no-transition .home-banner-wrap {
    animation: unset;
}

.home-banner-title {
    font-size: 36px;
    color: #fff;
    margin-bottom: 30px;
    line-height: 59px;
}

.home-banner-desc {
    width: 540px;
    font-size: 14px;
    color: #fff;
    line-height: 22px;
}

.banner-background-image {
    width: 100%;
    height: 100%;
}

/* .xcloud-homepage-banner.active .banner-background-image {
    animation: fadeInOut .8s ease-in-out;
    -webkit-animation: fadeInOut .8s ease-in-out;
} */

.banner-tab {
    width: 100%;
    position: absolute;
    text-align: center;
    bottom: 20px;
}

.banner-tab-wrap li {
    display: inline-block;
    width: 80px;
    height: 6px;
    background-color: rgba(255, 255, 255, .3);
    margin-right: 30px;
    cursor: pointer;
    transition: all .3s;
}

.banner-tab-wrap li:hover {
    background-color: rgba(255, 255, 255, .5);
}

.banner-tab-wrap li.active {
    background-color: #1367fa;
}

.home-banner-box .banner-main .ask {
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: #fff;
    letter-spacing: 0.05px;
    text-align: center;
    background: rgba(255, 255, 255, 0.11);
    border: 1px solid #ededed;
    border-radius: 2px;
    padding: 10px 32px;
    margin-top: 48px;
    display: inline-block;
}

.home-banner-box .banner-main .ask:hover {
    background: #1367fa;
    border: 1px solid #1367fa;
}

.home-banner-box .banner-main .home-ask {
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: #fff;
    letter-spacing: 0.05px;
    text-align: center;
    background: rgba(255, 255, 255, 0.11);
    border: 1px solid #ededed;
    border-radius: 2px;
    padding: 10px 32px;
    margin-top: 48px;
    display: inline-block;
}

.home-banner-box .banner-main .home-ask:hover {
    color: #1367fa;
    background: rgba(255, 255, 255);
    border: 1px solid #1367fa;
}

.home-banner-box .banner-main .no-ask {
    display: none;
}

@keyframes bannerKeysFrame {
    0% {
        transform: translateY(40px);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }

    30% {
        opacity: .3;
    }

    50% {
        opacity: .5;
    }

    75% {
        opacity: .7;
    }

    100% {
        opacity: 1;
    }
}
