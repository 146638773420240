.solutionMenu {
    height: 60px;
    width: 100%;
    border-bottom: 1px solid #d8d8d8;
    display: flex;
    align-items: center;
    justify-content: center;
}

.solutionMenuItem {
    margin: 0 4vw;
    cursor: pointer;
    padding: 22px 0;
}

.activeMenuItem {
    margin: 0 4vw;
    cursor: pointer;
    padding: 22px 0;
    color: #005def;
    border-bottom: 2px solid #005def;
}
