.newBanner {
    position: relative;
}

.solutionBannerImg {
    width: 100%;
}

.solutionBannerContent {
    position: absolute;
    top: 140px;
    left: 12.75vw;
}

.solutionBannerTitle {
    font-family: PingFangSC-Semibold;
    font-size: 40px;
    color: #000;
    letter-spacing: 0;
    line-height: 62px;
    font-weight: 600;
}

.solutionBannerDesc {
    font-family: PingFangSC-Light;
    font-size: 14px;
    color: #666;
    letter-spacing: 0;
    line-height: 30px;
    font-weight: 200;
    width: 26vw;
}

.solutionBannerBtn {
    color: #fff;
    background: #1763ff;
    border-radius: 2px;
    width: 5vw;
    padding: 11px 1vw;
    text-align: center;
    margin-top: 25px;
    cursor: pointer;
}

.solutionBannerBtn:hover {
    background: #0f4cff;
}
