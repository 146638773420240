.solutionChallenge {
    /* width: 100vw; */
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f8f8f8;
}

.challengeTitle {
    margin-top: 100px;
    margin-bottom: 76px;
    font-family: PingFangSC-Light;
    font-size: 36px;
    color: #333;
    letter-spacing: 0;
    text-align: center;
    font-weight: 200;
}

.challengeData {
    margin: 0 12.75vw;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 36px;
}

.challengeItem {
    display: flex;
    margin-bottom: 54px;
    width: 32vw;
}

.challengeItemIcon {
    display: block;
    height: 54px;
    margin-right: 2vw;
}

.challengeItemTitle {
    font-family: PingFangSC-Medium;
    font-size: 22px;
    color: #333;
    letter-spacing: 0;
    font-weight: 500;
    line-height: 30px;
}

.challengeItemContent {
    margin-top: 12px;
    font-family: PingFangSC-Light;
    font-size: 14px;
    color: #666;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 200;
}
