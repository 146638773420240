.header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
}

.headerBar {
    box-sizing: border-box;
    width: 100vw;
    height: 60px;
    padding: 0 12.75vw;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    color: #fff;
    letter-spacing: -0.15px;
    text-align: center;
    font-weight: 400;
}

.headerBar:hover {
    color: #16181a !important;
    background-color: #fff !important;
    border-bottom: 1px solid #ccc;
}

.whiteHeader,
.whiteHeader:hover {
    color: #16181a !important;
    background-color: #fff !important;
    border-bottom: 1px solid #ccc;
}

.headerBarLeft {
    display: flex;
    align-items: center;
    font-family: PingFangSC-Regular;
}

.headerItem {
    height: 20px;
    width: 5vw;
    margin-left: 2.6vw;
    padding: 20px 0;
    cursor: pointer;
    border-bottom: 1px solid transparent;
}

.headerItem:hover,
.headerItem:active,
.headerItem:focus {
    border-bottom: 2px solid #0076f9;
    color: #0076f9;
}

.activeItem {
    color: #0076f9;
    border-bottom: 2px solid #0076f9;
}

.logo {
    display: block;
    /* width: 8vw; */
    /* 新logo */
    width: 6vw;
    margin-right: 2vw;
    /* margin-top: 17px; */
    cursor: pointer;
}

.solution {
    margin-top: 27px;
    width: max-content;
    background-color: #fff;
    padding: 40px 2vw;
}

.solutionItem {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.solutionItem:last-child {
    margin-bottom: 0;
}

.solutionItem:hover div {
    color: #0076f9;
}

.solutionTitle {
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: #333;
    letter-spacing: -0.15px;
    font-weight: 500;
}

.solutionDesc {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    margin-top: 12px;
    color: #666;
    letter-spacing: -0.13px;
    line-height: 12px;
    font-weight: 400;
}
