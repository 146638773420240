.productCarousel {
    padding-top: 93px;
    padding-bottom: 103px;
    background: #f8f8f8;
}

.productCarouselTitle {
    font-family: PingFangSC-Light;
    font-size: 36px;
    color: #333;
    letter-spacing: 0;
    text-align: center;
    font-weight: 200;
}

.productCarouselContent {
    margin-top: 74px;
    padding: 0 12.75vw;
    display: flex;
    /* align-items: center; */
    /* justify-content: space-between; */
}

.productCarouselImg {
    width: 43vw;
    height: 100%;
    margin-right: 2.4vw;
}

.productCarouselRight {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.productCarouselRightTitle {
    font-family: PingFangSC-Medium;
    font-size: 24px;
    color: #333;
    letter-spacing: 0;
    font-weight: 500;
}

.productCarouselRightDesc {
    font-family: PingFangSC-Light;
    font-size: 14px;
    color: #666660;
    width: 29vw;
    height: 6vw;
    letter-spacing: 0;
    line-height: 23px;
    font-weight: 200;
    margin-top: 10px;
}

.carouselButtonImg {
    height: 2.6vw;
    margin: 2vw 0;
    margin-bottom: 1vw;
    margin-right: 0.9vw;
    cursor: pointer;
}

.carouselCards {
    display: flex;
    flex-wrap: no-wrap;
    align-items: center;
    /* justify-content: space-between; */
}

.carouselCard {
    position: relative;
    cursor: pointer;
    height: 9.3vw;
    width: 9.3vw;
    margin-right: 0.7vw;
}

.carouselCard:last-child {
    margin-right: 0;
}

.carouselCardImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.carouselCardTitle {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 9.3vw;
    width: 9.3vw;
    background-color: rgba(0, 0, 0, 0.39);
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.carouselCardTitleImg {
    width: 1.4vw;
    margin-bottom: 10px;
}

.carouselCardTitleText {
    font-size: 14px;
    color: #fff;
    letter-spacing: 0;
    text-align: center;
    line-height: 33px;
    font-weight: 200;
}
