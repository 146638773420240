.float-body {
    position: fixed;
    padding: 10px 4px;
    width: 30px;
    font-size: 16px;
    line-height: 18px;
    right: 0;
    bottom: 100px;
    text-align: center;
    border-radius: 3%;
    background: #24232d;
}

.float-title {
    margin: 0 auto;
    color: #fff;
}
