.deployment {
    background-color: #f8f8f8;
    padding-top: 93px;
    padding-bottom: 103px;
}

.deploymentTitle {
    font-family: PingFangSC-Light;
    font-size: 36px;
    color: #333;
    letter-spacing: 0;
    text-align: center;
    font-weight: 200;
}

.deploymentContent {
    margin-top: 74px;
    padding: 0 12.75vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.deploymentCard {
    box-sizing: border-box;
    background: #fff;
    border-radius: 20px;
    padding: 36px 1.8vw;
    height: 300px;
    width: 20vw;
    position: relative;
}

.deploymentCardTitle {
    font-family: PingFangSC-Medium;
    font-size: 24px;
    color: #333;
    letter-spacing: 0;
    font-weight: 500;
}

.deploymentIcon {
    /* width: 8.33vw; */
    height: 180px;
    position: absolute;
    bottom: 36px;
    right: 1.8vw;
}

.deploymentCardDetails {
    margin-top: 13px;
}

.deploymentCardDetailImg {
    height: 16px;
    width: 16px;
    margin-right: 0.5vw;
    background-image: url('../../../../assets/images/home/ok.svg');
    background-size: 16px 16px;
}

.deploymentCardDetail {
    font-family: PingFangSC-Light;
    font-size: 14px;
    color: #999;
    letter-spacing: 0;
    line-height: 26px;
    font-weight: 200;
    display: flex;
    align-items: center;
}

.deploymentCard:hover {
    background: #005def;
}

.deploymentCard:hover div {
    color: #fff;
}

.deploymentCard:hover .deploymentCardDetailImg {
    background-image: url('../../../../assets/images/home/okwhite.svg');
    background-size: 16px 16px;
}
