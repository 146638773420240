.customer {
    /* width: 100vw; */
}

.customerTitle {
    margin-top: 100px;
    margin-bottom: 60px;
    font-family: PingFangSC-Light;
    font-size: 36px;
    color: #333;
    letter-spacing: 0;
    text-align: center;
    line-height: 36px;
    font-weight: 200;
}

.customerContent {
    display: flex;
    /* position: relative; */
}

.customerContentImg {
    position: relative;
    width: 50vw;
}

.blueBar {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 82%;
    width: 3vw;
    background-color: #005def;
}

.triangle {
    position: absolute;
    right: 3vw;
    bottom: 41%;
    border-style: solid;
    border-width: 20px 20px 20px 20px;
    border-color: transparent #005def transparent transparent;
    width: 0px;
    height: 0px;
}

.customerImg {
    width: 50vw;
    height: 560px;
}

.customerContentRight {
    /* width: 50%; */
    flex: 1 auto;
    display: flex;
    flex-direction: column;
}

.customerContentMenus {
    display: flex;
    border-top: 1px solid #d8d8d8;
    height: 100px;
}

.customerContentMenu {
    box-sizing: border-box;
    border-top: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    padding: 1.87vw;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.customerContentMenuActive {
    box-sizing: border-box;
    border-top: 4px solid #0166f4;
    border-right: 1px solid #d8d8d8;
    padding: 1.87vw;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menuLogo {
    width: 7vw;
    display: block;
}

.customerDetail {
    flex: 1 auto;
    background: #005def;
    /* width: 45%; */
    padding-left: 1.69vw;
}

.customerDetailTitle {
    font-family: PingFangSC-Medium;
    font-size: 24px;
    color: #fff;
    letter-spacing: -0.26px;
    font-weight: 500;
    margin-top: 80px;
    margin-bottom: 35px;
}

.customerDetailContent {
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #fff;
    letter-spacing: -0.18px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 80px;
    width: 30vw;
}

.customerDetailBtn {
    color: #fff;
    border: 1px solid #fff;
    border-radius: 20px;
    text-align: center;
    padding: 12px;
    width: 6.77vw;
    /* margin-bottom: 80px;; */
}

.customerDetailBtn:hover {
    background: rgba(255, 255, 255, 0.10);
}

.seeMore {
    height: 190px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.seeMoreLink {
    font-family: PingFangSC-Light;
    font-size: 22px;
    color: #333;
    letter-spacing: -0.24px;
    font-weight: 200;
    height: 30px;
    line-height: 30px;
    display: flex;
    align-items: center;
}

.seeMoreLinkTo {
    height: 21px;
    display: block;
    margin-left: 11px;
}
