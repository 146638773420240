.band-container {
    min-width: 1200px;
    width: 100%;
    background-color: #fafafa;
}

.band-container .band-inner {
    width: 1200px;
    margin: 0 auto;
}

.band-container .band-wrap {
    padding-bottom: 50px;
    padding-top: 20px;
}

.band-container .band-wrap:last-child {
    /* margin-bottom: 80px; */
}

.band-container .band-wrap:before,
.band-container .band-wrap:after {
    content: "";
    display: block;
    clear: both;
}

.band-container .top-title {
    font-size: 30px;
    color: #233149;
    line-height: 42px;
    text-align: center;
    margin-bottom: 50px;
}

.band-container .top-title:first-child {
    margin-top: 30px;
}

.band-container .band-wrap ul.center {
    text-align: center;
}

.band-container .band-wrap ul:after,
.band-container .band-wrap ul:before {
    content: "";
    display: block;
    clear: both;
}

.band-container .band-wrap li {
    width: 380px;
    height: 450px;
    text-align: center;
    align-items: center;
    margin-bottom: 30px;
    float: left;
    background: #fff;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .05);
    border-radius: 10px;
    vertical-align: text-top;
    transition: box-shadow .3s;
}

.band-container .band-wrap li:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .15);
}

.band-wrap ul.center {
    text-align: center;
}

.band-container .band-wrap ul.center li {
    display: inline-block;
    float: unset;
}

.band-container .band-wrap li:nth-child(3n-1) {
    margin: 0 30px;
}

.band-container .band-wrap li .band-logo {
    margin-top: 35px;
    margin-bottom: 20px;
}

.band-container .logo img {
    width: 280px;
    height: 126px;
}

.band-container .band-wrap li .band-title {
    line-height: 25px;
    text-align: center;
    margin-bottom: 21px;
    font-size: 18px;
    color: #333;
    letter-spacing: -.2px;
}

.band-container .band-wrap li .bandLabel {
    margin-bottom: 20px;
}

.band-container .band-wrap li .bandLabel span {
    padding: 3px 10px;
    border-radius: 2px;
    font-size: 12px;
    letter-spacing: -.13px;
    line-height: 17px;
}

.band-container .band-wrap li .bandLabel span:nth-child(2n+2) {
    margin-left: 20px;
    margin-right: 20px;
}

.band-container .band-wrap li .bandLabellabelDoubleColor span:nth-child(2n+2) {
    margin-left: 20px;
}

.band-container .band-wrap li .bandLabel.labelDoubleColor span.c1 {
    background: rgba(4, 112, 255, .2);
    border: 1px solid #0470ff;
    color: #0470ff;
}

.band-container .band-wrap li .bandLabel.labelDoubleColor span.c2 {
    background: rgba(35, 49, 73, .2);
    border: 1px solid #233149;
    color: #233149;
}

.band-container .band-wrap li .bandLabel span.c3 {
    background: #56cfcf;
    border: 1px solid #56cfcf;
    color: #fff;
}

.band-container .band-wrap li .bandLabel span.c1 {
    background: #233149;
    border: 1px solid #233149;
    color: #fff;
}

.band-container .band-wrap li .bandLabel span.c2 {
    background: #0470ff;
    border: 1px solid #0091ff;
    color: #fff;
}

.band-container .band-wrap li .band-desc {
    padding: 0 29px;
    font-size: 14px;
    color: #333;
    letter-spacing: -.15px;
    line-height: 28px;
    text-align: left;
}
