.xcloud-footer {
    /* max-width: 1920px; */
    padding: 0 12.75vw;
    background-color: #000;
    clear: both;
}

.separateLine {
    background: rgba(153, 153, 153, .12);
    height: 1px;
    width: 100%;
}

.xcloud-footer .footer-logo {
    float: left;
    margin-top: 81px;
    margin-right: 144px;
}

.xcloud-footer .footer-logo img {
    /* 新logo */
    height: 32px;
    /* height: 25px; */
}

.xcloud-footer .xcloud-footer-main-services {
    padding: 40px 0 30px 0;
    display: flex;
}

.xcloud-footer .main-services {
    flex-grow: 1;
}

.xcloud-footer .main-services .title {
    color: #fff;
    font-size: 16px;
}

.xcloud-footer .main-services .xcloud-link-box {
    display: block;
    margin-top: 30px;
}

.xcloud-footer .main-services .xcloud-link {
    color: #ccc;
    text-decoration: none;
    font-size: 14px;
    transition: color .3s;
}

.xcloud-footer .main-services .xcloud-link:hover {
    color: #005def;
}

.xcloud-footer .main-services.noHighlight .xcloud-link {
    cursor: default;
}

.xcloud-footer .main-services.noHighlight .xcloud-link:hover {
    color: #005def;
    /* color: #969baa; */
}

.copyright {
    padding: 24px 0;
    font-size: 14px;
    text-align: center;
}

.copyright span {
    margin-right: 4px;
    font-size: 12px;
    color: #ccc;
}

.copyright span a {
    color: #ccc;
}

.copyright span a:hover {
    text-decoration: underline;
}
