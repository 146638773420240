.imgBanner {
    position: relative;
}

.bannerImg {
    width: 100vw;
}

.bannerTitle {
    position: absolute;
    top: 20%;
    left: 12.75vw;
}

.aboutTitle {
    font-family: 'Montserrat-Medium';
    font-size: 50px;
    color: #333;
    letter-spacing: 0;
    line-height: 62px;
    font-weight: 500;
}

.aboutMiniTitle {
    font-family: 'Montserrat-Medium';
    font-size: 40px;
    color: #333;
    letter-spacing: 0;
    line-height: 62px;
    font-weight: 500;
}

.titleButton {
    margin-top: 45px;
}

.aboutDetail {
    padding: 100px 12.75vw;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.aboutWords {
    width: 50%;
    padding-right: 4vw;
    font-family: PingFangSC-Light;
    font-size: 20px;
    color: #666;
    letter-spacing: 0;
    line-height: 50px;
    font-weight: 200;
    border-right: 2px solid #e0e0e0;
}

.numTitle {
    font-family: 'Montserrat-Regular';
    font-size: 32px;
    color: #333;
    letter-spacing: 0;
    line-height: 32px;
    font-weight: 400;
}

.numUnit {
    font-family: PingFangSC-Thin;
    font-size: 18px;
    color: #333;
    letter-spacing: 0;
    line-height: 32px;
    font-weight: 200;
}

.numUnitNone {
    font-family: PingFangSC-Thin;
    font-size: 18px;
    color: #fff;
    letter-spacing: 0;
    line-height: 32px;
    font-weight: 200;
}

.aboutNumTop {
    display: flex;
    align-items: baseline;
}

.numName {
    margin-top: 18px;
    font-family: PingFangSC-Light;
    font-size: 14px;
    color: #666;
    letter-spacing: 0;
    line-height: 14px;
    font-weight: 200;
}

.aboutNum {
    margin-right: 3vw;
    margin-bottom: 50px;
}

.aboutNums {
    display: flex;
    flex: 1;
    padding-left: 4vw;
    align-items: center;
    /* justify-content: space-between; */
    flex-wrap: wrap;
}

.showVideo {
    background: #005def;
    border-radius: 20px;
    border: none;
    font-size: 16px;
    color: #fff;
    letter-spacing: -0.18px;
    text-align: right;
    font-weight: 400;
    padding: 9px 33px;
    cursor: pointer;
}

.showVideo:hover,
.showVideo:active {
    background: #04c;
}

.videoModel {
    width: 100vw;
    height: calc(100vh);
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.videoTag {
    width: 70%;
}

.closeVideo {
    position: absolute;
    top: 24vh;
    right: 15vw;
    width: 40px;
    height: 40px;
    cursor: pointer;
}
