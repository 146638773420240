.productScenarios {
    /* height: 460px; */
    padding: 100px 12.75vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.scenariosName {
    margin-bottom: 76px;
    font-family: PingFangSC-Light;
    font-size: 36px;
    color: #333;
    letter-spacing: 0;
    text-align: center;
    font-weight: 200;
}

.scenariosContent {
    display: flex;
    justify-content: space-between;
}

.scenariosImg {
    width: 43vw;
    height: 25vw;
    display: flex;
    justify-content: space-between;
    margin-right: 1.8vw;
}

.scenariosDesc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.scenariosItem {
    height: 8vw;
    padding: 2vw;
    background: #f4f4f4;
    cursor: pointer;
    color: #333;
}

.scenariosItem:hover {
    background: #006cf8;
    color: #fff;
}

.scenariosTitle {
    font-family: PingFangSC-Medium;
    font-size: 24px;
    letter-spacing: 0;
    text-align: justify;
    line-height: 20px;
    font-weight: 500;
}

.scenariosSubtitle {
    font-family: PingFangSC-Light;
    font-size: 14px;
    letter-spacing: 0;
    text-align: justify;
    line-height: 18px;
    font-weight: 200;
    margin-top: 1vw;
}

.scenariosActive {
    padding: 2vw;
    background: #0470ff;
    color: #fff;
}

.scenariosButtonImg {
    width: 2.6vw;
    /* height: 1.6vw; */
    margin-left: 1.8vw;
    margin-bottom: 1.8vw;
    cursor: pointer;
}

.scenariosButton {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
}
