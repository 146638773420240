.productCustomerCase {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    margin-top: 100px;
    margin-bottom: 76px;
    font-family: PingFangSC-Light;
    font-size: 36px;
    color: #333;
    letter-spacing: 0;
    text-align: center;
    font-weight: 200;
}

.content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.name {
    background: #0470ff;
    color: #fff;
    font-family: PingFang-SC-Light;
    font-size: 34px;
    letter-spacing: 0;
    font-weight: 200;
    width: 40%;
    height: 12vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.desc {
    height: 12vw;
    background-color: #f8f8f8;
    font-family: PingFangSC-Light;
    font-size: 1vw;
    color: #000;
    letter-spacing: 0.63px;
    text-align: justify;
    line-height: 34px;
    font-weight: 200;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2vw;
}
