.message {
    position: fixed;
    top: 85px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1000;
    max-width: 800px;
}

.messageContent {
    background: #fff;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 12px 24px;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 500;
    color: #333;
    line-height: 22px;
    display: flex;
    align-items: center;
}

.messageIcon {
    margin-right: 8px;
    height: 20px;
}
