.productService {
    /* width: 100vw; */
}

.productServiceTitlePart {
    padding-top: 100px;
}

.productServiceTitle {
    font-family: PingFangSC-Light;
    font-size: 36px;
    color: #333;
    letter-spacing: -0.4px;
    text-align: center;
    line-height: 36px;
    font-weight: 200;
}

.productServiceIconPart {
    margin-left: 12.75vw;
    margin-right: 12.75vw;
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.productServiceIcon {
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
}

.productServiceIcon:hover div {
    color: #005def;
}

.productServiceImg {
    width: 100px;
    height: 100px;
    overflow: hidden;
}

.productServiceImgUrl {
    width: 100px;
    /* transition:margin-top 2s;
  -webkit-transition:margin-top 2s; */
    cursor: pointer;
}

.productServiceImgUrl:hover {
    /* margin-top: -7200px; */
}

.productServiceIconText {
    margin-top: 20px;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #000;
    letter-spacing: -0.18px;
    text-align: center;
    font-weight: 400;
}

.productServiceUnderLine {
    width: 80px;
    height: 2px;
    background: #005def;
    margin-top: 18px;
}

.productServiceNoLine {
    width: 80px;
    height: 2px;
    background: #fff;
    margin-top: 18px;
}

.productServiceData {
    background: #f8f8f8;
    padding: 61px 12.75vw;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}

.productServiceDataNumTop {
    margin-bottom: 30px;
}

.productServiceDataNum {
    width: 13vw;
    margin-right: 2.76vw;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
}

.productServiceDataNumTitle {
    font-family: PingFangSC-Regular;
    font-size: 28px;
    color: #000;
    letter-spacing: 0;
    line-height: 26px;
    font-weight: 400;
}

.productServiceDataNumDesc {
    font-family: PingFangSC-Light;
    font-size: 14px;
    color: #999;
    letter-spacing: 0;
    line-height: 22px;
    font-weight: 200;
    margin-top: 19px;
}

.productServiceDataNumLine {
    height: 1px;
    width: 100%;
    background: #ccc;
    margin-bottom: 13px;
}

.productServiceDataNumItemTitle {
    display: flex;
    align-items: baseline;
}

.productServiceDataNumItemCount {
    font-family: 'Montserrat-Light';
    font-size: 28px;
    color: #333;
    letter-spacing: 0;
    line-height: 32px;
    font-weight: 200;
}

.productServiceDataNumItemUnit {
    font-family: 'Montserrat-ExtraLight';
    font-size: 18px;
    color: #333;
    letter-spacing: 0;
    line-height: 32px;
    font-weight: 200;
}

.productServiceDataNumName {
    font-family: PingFangSC-Light;
    font-size: 14px;
    color: #999;
    letter-spacing: 0;
    line-height: 14px;
    font-weight: 200;
    margin-top: 10px;
    margin-bottom: 39px;
}

.productServiceDataDetail {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
}

.productServiceDataCard {
    position: relative;
    background: #fff;
    border-radius: 20px;
    width: 40%;
    margin-bottom: 22px;
    margin-right: 1.04vw;
    padding: 30px 2.08vw;
    background-size: 0 0;
    background-repeat: no-repeat;
    background-position: right;
}


.productCardTitleName {
    font-family: PingFangSC-Medium;
    font-size: 20px;
    color: #333;
    letter-spacing: -0.22px;
    line-height: 20px;
    font-weight: 500;
}

.productCardTitleDesc {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #999;
    letter-spacing: -0.13px;
    line-height: 12px;
    font-weight: 400;
    margin-top: 14px;
}

.productCardContent {
    display: flex;
    flex-wrap: wrap;
    margin-top: 51px;
}

.productCardContentItem {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #666;
    letter-spacing: -0.13px;
    font-weight: 400;
    margin-right: 1vw;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.productCardContentItemNone {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #ccc;
    letter-spacing: -0.13px;
    font-weight: 400;
    display: flex;
    align-items: center;
    margin-right: 1vw;
    margin-bottom: 15px;
    cursor: not-allowed;
}

.productCardContentItemLink {
    background: url('../../../../assets/images/home/linkto.svg');
    background-size: 12px 12px;
    width: 12px;
    height: 12px;
    display: block;
    margin-left: 0.2vw;
}

.productServiceDataCard:hover {
    background: #005def;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: right;
}

.productServiceDataCard:hover div {
    color: #fff;
}

.productServiceDataCard:hover .productCardContentItemLink {
    background: url('../../../../assets/images/home/linknone.svg');
    background-size: 12px 12px;
}

.productServiceDataCard:hover .productCardContentItemNoneName {
    color: #ccc;
}
