
.contactBackground {
    width: 100%;
    background-color: #fff;
    position: relative;
    overflow: hidden;
}

.contactTop {
    width: 100%;
    height: 40vh;
    position: relative;
}

.contactBottom {
    width: 100%;
    height: 90vh;
}

.contactSurvey {
    position: absolute;
    left: 50%;
    top: 25%;
    transform: translate(-50%, 0);
    margin: 0;
    z-index: 1;
    background: #fff;
    color: #333;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.surveyFormTitle {
    font-size: 36px;
    letter-spacing: -0.4px;
    font-weight: bold;
    margin-top: 5vh;
}

.surveyMiniTitle {
    font-size: 14px;
    letter-spacing: -0.15px;
    margin-top: 2vh;
}

.surveyBody {
    margin-top: 3vh;
    font-size: 16px;
    letter-spacing: -0.18px;
    margin-left: 16vw;
    margin-right: 16vw;
}

.surveyImportant {
    color: #e02020;
    margin-right: 5px;
}

.surveyUnimportant {
    opacity: 0;
}

.trueNum {
    display: none;
}

.errorNum {
    font-size: 12px;
    color: #e02020;
    letter-spacing: -0.13px;
    margin-left: 110px;
    margin-top: -10px;
}

.surveyItem {
    margin: 2vh 0;
    position: relative;
    display: flex;
    align-items: center;
}

.surveyItemTitle {
    margin-right: 26px;
    width: 80px;
}

.contact-background .survey .form-body .item .name {
    margin-right: 26px;
}

.surveyItemResult {
    width: 360px;
    height: 36px;
    border: 1px solid #cecece;
    border-radius: 1px;
    font-size: 16px;
    padding: 6px 20px;
    display: block;
}

.surveyItemResult::placeholder {
    font-size: 16px;
    color: #999;
    letter-spacing: -0.18px;
}

.surveyItemResult:focus {
    outline: 1px solid #1367fa;
}

.surveyItemNoResult {
    width: 360px;
    height: 36px;
    border: 1px solid #e02020;
    border-radius: 1px;
    font-size: 16px;
    padding: 6px 20px;
    display: block;
}

.surveyItemNoResult::placeholder {
    font-size: 16px;
    color: #999;
    letter-spacing: -0.18px;
}

.surveyItemErrorResult {
    width: 360px;
    height: 36px;
    border: 1px solid #e02020;
    border-radius: 1px;
    font-size: 16px;
    padding: 6px 20px;
}

.surveyItemErrorResult:focus {
    outline: 1px solid #e02020;
}

.surveyCaptchaResult {
    width: 210px;
    margin-right: 10px;
    height: 36px;
    border: 1px solid #cecece;
    border-radius: 1px;
    font-size: 16px;
    padding: 6px 20px;
    display: block;
}

.surveyCaptchaResult::placeholder {
    font-size: 16px;
    color: #999;
    letter-spacing: -0.18px;
}

.surveyCaptchaResult:focus {
    outline: 1px solid #1367fa;
}

.surveyCaptchaNoResult {
    width: 210px;
    margin-right: 10px;
    height: 36px;
    border: 1px solid #e02020;
    border-radius: 1px;
    font-size: 16px;
    padding: 6px 20px;
    display: block;
}

.surveyCaptchaNoResult::placeholder {
    font-size: 16px;
    color: #999;
    letter-spacing: -0.18px;
}

.surveyCaptchaErrorResult {
    width: 210px;
    margin-right: 10px;
    height: 36px;
    border: 1px solid #e02020;
    border-radius: 1px;
    font-size: 16px;
    padding: 6px 20px;
}

.surveyCaptchaErrorResult:focus {
    outline: 1px solid #e02020;
}

.captchaImg {
    cursor: pointer;
}

.textArea {
    min-height: 130px;
    display: inline-block;
    vertical-align: middle;
}

.textCount {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.surveySubmit {
    background: #1367fa;
    border-radius: 2px;
    width: 136px;
    height: 42px;
    border: none;
    font-size: 14px;
    color: #fff;
    letter-spacing: 0.05px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 50px;
}

.surveySubmit:hover {
    cursor: pointer;
    background: #2ba0fd;
}

.surveySuccess {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 434px;
    margin-right: 434px;
}

.successIcon {
    display: block;
    width: 80px;
    height: 80px;
    margin-top: 210px;
}

.successTitle {
    font-size: 30px;
    font-weight: bold;
    color: #333;
    letter-spacing: -0.33px;
    margin-top: 45px;
}

.successDesc {
    font-size: 14px;
    color: #333;
    width: 240px;
    letter-spacing: -0.15px;
    margin-top: 20px;
}

.successBtn {
    background: #1367fa;
    border-radius: 2px;
    border: 1px solid #1367fa;
    width: 136px;
    height: 42px;
    color: #fff;
    margin-top: 100px;
    margin-bottom: 200px;
}

.successBtn:hover {
    cursor: pointer;
    background: #2ba0fd;
    border: 1px solid #2ba0fd;
}
