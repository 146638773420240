@media screen and (min-width:321px) and (max-width:375px) {
    html {
        font-size: 11px;
    }
}

@media screen and (min-width:376px) and (max-width:414px) {
    html {
        font-size: 12px;
    }
}
@media screen and (min-width:415px) and (max-width:639px) {
    html {
        font-size: 15px;
    }
}
@media screen and (min-width:640px) and (max-width:719px) {
    html {
        font-size: 20px;
    }
}
@media screen and (min-width:720px) and (max-width:749px) {
    html {
        font-size: 22.5px;
    }
}
@media screen and (min-width:750px) and (max-width:799px) {
    html {
        font-size: 23.5px;
    }
}
@media screen and (min-width:1200px) {
    html {
        font-size: 12px;
    }
}

body {
    font-family: "PingFang SC", "Microsoft Yahei", Helvetica, Roboto, Arial, Avenir, "Noto Sans SC", "Segoe UI", "Hiragino Sans GB W6", STHeiti, "Microsoft Sans Serif", sans-serif;
}

.pr {
    position: relative;
}

.pa {
    position: absolute;
}

.tl {
    text-align: left;
}
