.videoBanner {
    position: relative;
}

.videoPart {
    width: 100%;
}

.homeBannerTitle {
    position: absolute;
    top: 30%;
    left: 12.75vw;
    /* transform: translate(8%, 0); */
}

.bigTitle {
    font-family: 'Montserrat-Medium';
    font-size: 54px;
    color: #fff;
    letter-spacing: 0;
    line-height: 75px;
    font-weight: 500;
}

.titleContentTop {
    font-family: PingFangSC-Light;
    font-size: 14px;
    color: #fff;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 200;
    margin-top: 18px;
}

.titleContent {
    font-family: PingFangSC-Light;
    font-size: 14px;
    color: #fff;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 200;
}

.titleButton {
    margin-top: 45px;
}

.showVideo {
    background: #005def;
    border-radius: 20px;
    border: none;
    font-size: 16px;
    color: #fff;
    letter-spacing: -0.18px;
    text-align: right;
    font-weight: 400;
    padding: 9px 33px;
    cursor: pointer;
}

.showVideo:hover,
.showVideo:active {
    background: #04c;
}

.videoModel {
    width: 100vw;
    height: calc(100vh);
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.videoTag {
    width: 70%;
}

.closeVideo {
    position: absolute;
    top: 24vh;
    right: 15vw;
    width: 40px;
    height: 40px;
    cursor: pointer;
}
