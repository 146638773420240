.architecture {
    /* width: 100vw; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.architectureTitle {
    margin-top: 100px;
    margin-bottom: 76px;
    font-family: PingFangSC-Light;
    font-size: 36px;
    color: #333;
    letter-spacing: 0;
    text-align: center;
    font-weight: 200;
}

.architectureDetail {
    margin: 0 12.75vw;
    margin-bottom: 110px;
    display: flex;
}

.architectureImg {
    width: 54vw;
    height: 100%;
    display: block;
    margin-right: 2.6vw;
    /* image-rendering: crisp-edges; */
    image-rendering: -webkit-optimize-contrast;
}

.architectureDesc {
    flex: 1;
    padding: 36px 1.8vw;
    background: #f8f8f8;
    border: 1px solid #ccc;
    border-radius: 20px;
}

.architectureDescTitle {
    font-family: PingFangSC-Medium;
    font-size: 20px;
    color: #000;
    letter-spacing: 0;
    font-weight: 500;
    padding-bottom: 16px;
    border-bottom: 1px solid #666;
}

.architectureItem {
    font-family: PingFangSC-Light;
    font-size: 14px;
    color: #666;
    letter-spacing: 0;
    text-align: justify;
    line-height: 24px;
    font-weight: 200;
    margin: 20px 0;
}
