.solutionAdvantage {
    height: 600px;
    padding: 100px 12.75vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #f7f7f7; */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.advantageName {
    margin-bottom: 76px;
    font-family: PingFangSC-Light;
    font-size: 36px;
    color: #333;
    letter-spacing: 0;
    text-align: center;
    font-weight: 200;
}

.advantageData {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.advantageLeft,
.advantageRight {
    height: 100%;
    padding-top: 80px;
}

.advantageRight {
    align-self: flex-start;
}

.advantageItem {
    margin-bottom: 54px;
}

.advantageRightItem {
    margin-bottom: 54px;
    display: flex;
    align-items: baseline;
}

.advantageItemTitlePart {
    display: flex;
    align-items: center;
}

.advantageItemTitle {
    font-family: PingFangSC-Medium;
    font-size: 24px;
    color: #333;
    letter-spacing: 0;
    font-weight: 500;
}

.advantageItemContent {
    width: 18vw;
    margin-top: 9px;
    font-family: PingFangSC-Light;
    font-size: 14px;
    color: #666;
    letter-spacing: 0;
    line-height: 26px;
    font-weight: 200;
}

.advantageLink {
    display: flex;
    align-items: center;
    margin: 0 1vw;
}

.advantagePoint {
    height: 9px;
    width: 9px;
    border-radius: 50%;
    background-color: #d8d8d8;
}

.advantageLine {
    height: 2px;
    width: 16vw;
    background-color: #d8d8d8;
}

.advantageRightLine {
    height: 2px;
    width: 10vw;
    background-color: #d8d8d8;
}
