.solutionCase {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 12.75vw;
    background-color: #f8f8f8;
}

.solutionCaseTitle {
    margin-top: 100px;
    margin-bottom: 76px;
    font-family: PingFangSC-Light;
    font-size: 36px;
    color: #333;
    letter-spacing: 0;
    text-align: center;
    font-weight: 200;
}

.solutionCaseContent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.solutionCaseCard {
    width: 31vw;
    height: 19vw;
    margin-bottom: 100px;
    background-image: linear-gradient(90deg, #1e64d8 27%, rgba(30, 100, 216, 0.30) 100%);
    border-radius: 20px;
    padding: 42px 2vw;
    position: relative;
}

.solutionCaseItemTitle {
    width: 28vw;
    font-family: PingFangSC-Medium;
    font-size: 1.6vw;
    color: #fff;
    letter-spacing: 0;
    font-weight: 500;
    padding-bottom: 20px;
    border-bottom: 2px solid #fff;
    line-height: 30px;
}

.solutionCaseItemDesc {
    width: 20vw;
    font-family: PingFangSC-Light;
    font-size: 14px;
    color: #fff;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 200;
    margin-top: 18px;
}

.solutionCaseImg {
    width: 18vw;
    position: absolute;
    right: 0;
    bottom: -26px;
}
