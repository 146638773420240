.headerContent {
    margin-top: 1px;
    height: 360px;
    background-color: #fff;
    position: relative;
    display: flex;
    padding-left: 12.75vw;
    overflow: hidden;
    padding-top: 54px;
    padding-bottom: 54px;
}

.contentImg {
    position: absolute;
    bottom: -90px;
    right: -2vw;
    width: 30vw;
}

.headerContentLeft {
    border-right: 1px solid #dcdcdc;
    padding-right: 4.16vw;
}

.headerContentRight {
    display: flex;
    flex: 1;
    padding-left: 4.16vw;
    /* justify-content: space-around; */
    /* align-items: center; */
}

.headerTitles {
    margin-bottom: 40px;
}

.headerTitle {
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: #999;
    letter-spacing: -0.15px;
    font-weight: 400;
    margin-bottom: 9px;
    cursor: pointer;
}

.headerTitle:hover,
.headerTitle:active {
    color: #333;
}

.headerTitleActive {
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: #333;
    letter-spacing: -0.15px;
    font-weight: 400;
    margin-bottom: 9px;
    cursor: pointer;
}

.titleSelected {
    height: 1px;
    width: 60px;
    background-color: #1e64d8;
}

.headerProduct {
    width: 10vw;
    margin-right: 3vw;
}

.headerProductTitle {
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #333;
    letter-spacing: -0.15px;
    font-weight: 500;
    padding-bottom: 16px;
    border-bottom: 1px solid #ccc;
}

.headerProductDetails {
    margin-top: 20px;
}

.productDetail {
    display: block;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #666;
    letter-spacing: -0.13px;
    line-height: 40px;
    font-weight: 400;
    cursor: pointer;
}

.productDetail:hover {
    color: #1e64d8;
}

.productDetailNoLink {
    display: block;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #666;
    letter-spacing: -0.13px;
    line-height: 40px;
    font-weight: 400;
    cursor: not-allowed;
}

.productDetailNoLink:hover {
    color: #ccc;
}
