.productFeatures {
    padding-top: 93px;
    padding-bottom: 103px;
}

.featureTitle {
    font-family: PingFangSC-Light;
    font-size: 36px;
    color: #333;
    letter-spacing: 0;
    text-align: center;
    font-weight: 200;
}

.featureContent {
    margin-top: 74px;
    padding: 0 12.75vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.featureCard {
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #c4c4c4;
    border-radius: 20px;
    padding: 1.5vw;
    /* width: 24vw; */
    height: 26vw;
    margin-right: 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.featureCard:last-child {
    margin-right: 0;
}

.featureCardLogo {
    height: 3.6vw;
    display: block;
    margin-bottom: 1.4vw;
}

.featureCardTitle {
    font-family: PingFangSC-Semibold;
    font-size: 22px;
    color: #333;
    letter-spacing: 0;
    text-align: center;
    font-weight: 600;
    margin-bottom: 10px;
}

.featureCardDetail {
    font-family: PingFangSC-Light;
    font-size: 14px;
    color: #666660;
    letter-spacing: 1px;
    /* text-align: center; */
    line-height: 20px;
    font-weight: 200;
    height: 4vw;
}

.featureCard:hover {
    background: #005def;
}

.featureCard:hover .featureCardLogo {
    /* display: none; */
}

.featureCard:hover div {
    color: #fff;
}
